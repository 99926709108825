import { useNavigate } from "react-router-dom";
import { Button } from "./Button";

export const Nav = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="h-20 mt-6"></div>
      <div className="top-6 left-0 z-10 fixed right-0 m-auto rounded-xl flex items-center justify-between w-[95%] px-4 h-20 bg-brand-light bg-opacity-50 backdrop-blur-xl">
        <div className="text-3xl h-fit w-fit overflow-visible italic font-bold">
          <span className="gradient-background text-background pr-[4px] pb-2 italic text-transparent overflow-visible">
            gold
          </span>
          <span className="absolute -translate-x-1">earn</span>
        </div>

        <div className="flex gap-2">
          <Button
            label="LOGIN"
            onClick={() => {
              navigate("/login");
            }}
          />
          <Button
            label="SIGNUP"
            color="purple"
            onClick={() => {
              navigate("/signup");
            }}
          />
        </div>
      </div>
    </div>
  );
};
