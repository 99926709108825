import React from "react";
import { DashboardNav } from "../components/DashboardNav";
import { OfferwallThumbnail } from "../components/earn/OfferwallThumbnail";
import useGlobalState from "../state/globalState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export const Earn = () => {
  const [userData] = useGlobalState("userData");

  return (
    <div className="flex flex-col gap-8 px-8 pb-20 sm:px-20 lg:px-44">
      <DashboardNav activeTab="earn" />

      <div className="flex w-full justify-center">
        <div className="w-fit h-fit px-4 py-2 bg-brand-light bg-opacity-80 border-[1px] border-yellow-400 flex items-center gap-8 rounded-md">
          <FontAwesomeIcon
            icon={faWarning}
            className="h-16 w-16 text-yellow-400"
          />
          <div>
            <div className="text-xl font-bold text-yellow-400 w-full text-center">
              WARNING
            </div>
            Make sure to disable adblockers and VPNs before completing offers.
          </div>
        </div>
      </div>

      <div>
        <div className="text-3xl">Offer walls</div>
        <div className="opacity-70">Complete offers and watch videos!</div>
        {userData && (
          <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-2">
            <OfferwallThumbnail
              image="/images/offerwalls/OfferToro.png"
              link={
                userData &&
                `https://www.offertoro.com/ifr/show/30202/${userData.uid}/14911`
              }
            />
            <OfferwallThumbnail
              image="/images/offerwalls/BitLabs.png"
              link={
                userData &&
                `https://web.bitlabs.ai/?uid=${userData.uid}&token=0dfa8ba7-52ba-4c7f-94d9-ba62774a30eb`
              }
            />
            <OfferwallThumbnail
              image="/images/offerwalls/adgem.png"
              comingSoon
            />
          </div>
        )}
      </div>
    </div>
  );
};
