import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useGlobalState from "../state/globalState";
import { Card } from "./Card";
import { NavLink } from "./dashboard/NavLink";
import "../styles/burger.css";

import { push as Menu } from "react-burger-menu";
import { MobileNavLink } from "./dashboard/MobileNavLink";
import { Button } from "./Button";

export const DashboardNav = ({ activeTab = "dashboard" }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [userData] = useGlobalState("userData");

  const isMobile = window.innerWidth < 768;

  return (
    <div>
      <div className="h-28 mt-6"></div>
      {isMobile && (
        <div className="top-6 left-0 z-20 fixed right-0 m-auto rounded-xl flex items-center justify-between w-[95%] px-4 h-20 bg-opacity-50 ">
          <Menu right>
            <FontAwesomeIcon
              icon={faUser}
              color="#7198FF"
              className="h-20 w-20"
            />
            <div className="text-center mt-4 text-2xl">
              Hello,{" "}
              <span className="font-bold">
                {userData && userData["username"]}
              </span>
              <Card className="flex items-center justify-center gap-2 h-fit p-2 text-xl shadow-none">
                {userData && Math.floor(userData.balance * 100) / 100}
                <img
                  src="/images/dollar.png"
                  alt="dollar icon"
                  className="h-7"
                />
              </Card>
            </div>

            <div>
              <MobileNavLink
                label="Dashboard"
                active={activeTab === "dashboard"}
                link="/dashboard"
              />
              <MobileNavLink
                label="Earn"
                active={activeTab === "earn"}
                link="/earn"
              />
              <MobileNavLink
                label="Payout"
                active={activeTab === "payout"}
                link="/payout"
              />
              <MobileNavLink
                label="Referrals"
                active={activeTab === "referrals"}
                link="/referrals"
              />
            </div>

            <Button
              label="LOGOUT"
              color="red"
              className="w-full"
              onClick={() => {
                removeCookie("token");
                navigate("/");
              }}
            />
          </Menu>
        </div>
      )}
      <div className="top-6 left-0 z-10 fixed right-0 m-auto rounded-xl flex items-center justify-between w-[95%] px-4 h-20 bg-brand-light bg-opacity-50 backdrop-blur-xl">
        <div className="text-3xl h-fit w-fit overflow-visible italic font-bold">
          <span className="gradient-background text-background pr-[4px] pb-2 italic text-transparent overflow-visible">
            gold
          </span>
          <span className="absolute -translate-x-1">earn</span>
        </div>

        {!isMobile && (
          <div className="flex gap-4">
            <NavLink
              label="Dashboard"
              active={activeTab === "dashboard"}
              link="/dashboard"
            />
            <NavLink label="Earn" active={activeTab === "earn"} link="/earn" />
            <NavLink
              label="Payout"
              active={activeTab === "payout"}
              link="/payout"
            />
            <NavLink
              label="Referrals"
              active={activeTab === "referrals"}
              link="/referrals"
            />
          </div>
        )}
        {!isMobile && (
          <div className="flex gap-4 items-center h-[60%]">
            <Card className="flex items-center justify-center gap-2 h-full p-2 text-xl">
              {userData && Math.floor(userData.balance * 100) / 100}
              <img
                src="/images/dollar.png"
                alt="dollar icon"
                className="h-[125%]"
              />
            </Card>
            {/* <FontAwesomeIcon
              icon={faUser}
              color="#7198FF"
              className="h-[60%] cursor-pointer"
            /> */}
            <FontAwesomeIcon
              icon={faRightFromBracket}
              color="#343955"
              className="h-[60%] cursor-pointer"
              onClick={() => {
                removeCookie("token");
                navigate("/");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
