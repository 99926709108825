import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Signup } from "./pages/Signup";
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { TOS } from "./pages/TOS";
import { Earn } from "./pages/Earn";
import { Payout } from "./pages/Payout";
import { CSGO } from "./pages/payouts/CSGO";
import { Roblox } from "./pages/payouts/Roblox";
import { ToastContainer } from "react-toastify";
import { useUpdateUserData } from "./utils/authentication";
import { Discord } from "./pages/payouts/Discord";
import { Litecoin } from "./pages/payouts/Litecoin";
import { Referrals } from "./pages/Referrals";
import { NordVPN } from "./pages/payouts/NordVPN";
import { VerifyAADS } from "./pages/extras/VerifyAADS";

const root = ReactDOM.createRoot(document.getElementById("root"));
const AppRender = () => {
  // eslint-disable-next-line no-unused-vars
  const userDataUpdate = useUpdateUserData();

  return (
    <div>
      <Routes>
        <Route path="/payout/nordvpn" element={<NordVPN />} exact />
        <Route path="/payout/litecoin" element={<Litecoin />} exact />
        <Route path="/payout/discord" element={<Discord />} exact />
        <Route path="/payout/roblox" element={<Roblox />} exact />
        <Route path="/payout/csgo" element={<CSGO />} exact />
        <Route path="/referrals" element={<Referrals />} exact />
        <Route path="/payout" element={<Payout />} exact />
        <Route path="/earn" element={<Earn />} exact />
        <Route path="/dashboard" element={<Dashboard />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/signup" element={<Signup />} exact />
        <Route path="/tos" element={<TOS />} exact />
        <Route path="/" element={<App />} exact />

        <Route path="/verify_aads" element={<VerifyAADS />} exact />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

root.render(
  <BrowserRouter>
    <AppRender />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
