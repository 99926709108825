export const SocialLink = ({ image, link }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className="relative">
      <img
        src={image}
        alt={image}
        className="rounded-md h-full hover:opacity-80 duration-100"
      />
      <div className="absolute bottom-2 right-2 text-xl flex gap-2 items-center">
        50 <img src="/images/dollar.png" alt="dollar" className="h-8" />
      </div>
    </a>
  );
};
