import React from "react";
import useGlobalState from "../../state/globalState";
import { month } from "../../utils/misc";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const Last7DaysChart = ({ setOffersCompletedLast7Days }) => {
  const [last7DaysEarnings, setLast7DaysEarnings] = React.useState([]);

  const [userData] = useGlobalState("userData");

  const Last7Days = () => {
    var result = [];
    for (var i = 0; i < 7; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(`${month[d.getMonth()]} ${d.getDate()}`);
    }

    return result;
  };

  React.useEffect(() => {
    if (userData) {
      // 604800
      const lastWeek = [];

      userData.offers_completed.forEach((offer) => {
        const date = new Date(offer.date_completed * 1000);
        const now = new Date();
        const diff = Math.abs(now - date);
        if (diff <= 604800000) {
          lastWeek.push(offer);
        }
      });

      const last7Days = Last7Days();
      const last7DaysOffers = [];

      last7Days.forEach((day) => {
        const offers = lastWeek.filter((offer) => {
          const date = new Date(offer.date_completed * 1000);
          return `${day}` === `${month[date.getMonth()]} ${date.getDate()}`;
        });
        last7DaysOffers.push({
          day: day,
          earnings:
            Math.floor(
              offers
                .map((val) => val.earnings)
                .reduce((partialSum, a) => partialSum + a, 0) * 100
            ) / 100,
        });
      });

      setOffersCompletedLast7Days(lastWeek.length);
      last7DaysOffers.reverse();
      setLast7DaysEarnings(last7DaysOffers);
    }
  }, [setOffersCompletedLast7Days, userData]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={last7DaysEarnings}
        margin={{
          top: 10,
          bottom: -10,
          left: -40,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="earnings"
          fill="url(#colorUv)"
          //   stroke="#8884d8"
          //   fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
