import classNames from "classnames";
import React from "react";
import { useCookies } from "react-cookie";
import useGlobalState from "../../state/globalState";
import { Button } from "../Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const RedeemCard = ({ title, image, price }) => {
  const [hovered, setHovered] = React.useState(false);
  const [userData, setUserData] = useGlobalState("userData");
  const [cookies] = useCookies();

  const redeem = () => {
    if (userData.balance < parseInt(price.replace(",", ""))) {
      const goldDifference =
        Math.floor(
          (parseInt(price.replace(",", "")) - userData.balance) * 100
        ) / 100;
      toast.error(`You need ${goldDifference} more gold for that!`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    fetch("https://api.goldearn.gg/redeem?prize=" + title, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: cookies.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          toast.error(data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          return;
        }

        setUserData((prev) => {
          return {
            ...prev,
            balance: prev.balance - parseInt(price.replace(",", "")),
          };
        });

        toast.success("Redeemed successfully!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  return (
    <div className="relative flex items-end sm:h-96">
      <div
        className="flex gap-8 w-full h-fit sm:h-64 bg-brand-light rounded-md p-4"
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        <img
          src={image}
          alt={title}
          className={classNames(
            "h-[10rem] sm:h-[20rem] sm:-mt-[6rem] rounded-md duration-200",
            {
              "rotated-image": !hovered,
            }
          )}
        />

        <div className="flex flex-col gap-4">
          <div className="text-2xl">{title}</div>
          <div className="flex gap-2 h-6 items-center text-lg">
            {price}
            <img
              src="/images/dollar.png"
              alt="dollar icon"
              className="h-full"
            />
          </div>
          <Button
            label="Redeem"
            color="gradient"
            className="py-4 px-8 text-xl rounded-xl w-fit"
            onClick={redeem}
          />
        </div>
      </div>
    </div>
  );
};
