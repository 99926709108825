import { Card } from "../Card";

export const WhyCard = ({ title, description, image, className }) => {
  return (
    <Card
      className={
        className +
        " w-full pt-8 pb-8 sm:pt-20 cursor-default hover:-translate-y-2 duration-100"
      }
    >
      <div className="flex justify-center h-20 w-full">
        <img
          src={`/images/${image}.png`}
          alt={image}
          className="max-h-[80%] max-w-[80%]"
        />
      </div>
      <div className="text-brand-light-blue font-bold text-2xl text-center">
        {title}
      </div>
      <div className="opacity-50">{description}</div>
    </Card>
  );
};
