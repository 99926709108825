export const JoinDiscordBanner = ({ start }) => {
  return (
    <div className="flex flex-col items-center gap-4 w-full p-4 bg-brand-light rounded-md text-center text-lg">
      {start} withdrawals are processed manually, please join our Discord server
      to recieve your rewards.
      <a
        href="https://discord.gg/bvh9CCF2xV"
        target="_blank"
        rel="noreferrer"
        className="bg-brand-super-light px-6 py-2 text-green-500 w-fit rounded-md text-base hover:text-green-400"
      >
        https://goldearn.gg/discord
      </a>
    </div>
  );
};
