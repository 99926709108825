import React from "react";

import { DashboardNav } from "../../components/DashboardNav";
import { JoinDiscordBanner } from "../../components/payout/JoinDiscordBanner";
import { RedeemCard } from "../../components/payout/RedeemCard";
import "../../styles/payouts.css";

export const Discord = () => {
  return (
    <div className="flex flex-col gap-8 px-8 pb-20 sm:px-20 lg:px-44">
      <DashboardNav activeTab="payout" />

      <JoinDiscordBanner start={"Discord"} />

      <div className="grid grid-cols-1 xl:px-40 2xl:px-0 2xl:grid-cols-2 2xl:gap-x-20 gap-y-4 sm:gap-y-10 mt-10">
        <RedeemCard
          title="Nitro Classic Gift - $5"
          price="5,000"
          image="/images/payouts/nitroclassicgift.jpg"
        />
        <RedeemCard
          title="Nitro Gift - $10"
          price="10,000"
          image="/images/payouts/nitrogift.jpg"
        />
      </div>
    </div>
  );
};
