import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const NavLink = ({ label, link, active }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(link)}
      className={classNames("text-xl cursor-pointer px-3 py-1", {
        "bg-brand-pink bg-opacity-60 rounded-md": active,
      })}
    >
      {label}
    </div>
  );
};
