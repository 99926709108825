export const StatBlock = ({ title, description, image }) => {
  return (
    <div className="flex flex-col items-center pt-8 w-full gap-2">
      <div className="h-16">
        <img
          src={`/images/${image}`}
          alt={image}
          className="max-h-[4rem] max-w-[4rem]"
        />
      </div>
      <div className="text-center">
        <div className="text-xl">{title}</div>
        <div className="text-lg opacity-50 font-bold">{description}</div>
      </div>
    </div>
  );
};
