import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const MobileNavLink = ({ label, link, active }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(link)}
      className={classNames("text-xl cursor-pointer px-3 py-1", {
        "border-l-4 border-brand-pink border-opacity-60": active,
      })}
    >
      {label}
    </div>
  );
};
