import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const Thumbnail = ({ image, link, disabled }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames("relative", {
        "cursor-pointer": !disabled,
      })}
      onClick={() => {
        navigate(link);
      }}
    >
      <img
        src={image}
        alt={image}
        className={classNames("w-full aspect-video duration-100", {
          "opacity-20": disabled,
          "hover:opacity-80": !disabled,
        })}
      />

      {disabled && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="bg-brand-gold text-brand-dark font-bold text-xl px-4 py-2 rounded-md max-w-[90%] max-h-[90%] sm:max-w-none text-center">
            Coming Soon
          </div>
        </div>
      )}
    </div>
  );
};
