import React from "react";

import "../App.css";
import { Nav } from "../components/Nav";

import { CompactPayouts } from "../components/landing/CompactPayouts";
import { LargePayouts } from "../components/landing/LargePayouts";
import { WhyWePay } from "../components/landing/WhyWePayList";
import { Card } from "../components/Card";
import { StatBlock } from "../components/landing/StatBlock";
import classNames from "classnames";
import { FAQItem } from "../components/landing/FAQItem";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [offerHovered, setOfferHovered] = React.useState(false);
  const [cookie] = useCookies();

  const [generalStats, setGeneralStats] = React.useState(undefined);

  if (searchParams.get("ref")) {
    localStorage.setItem("ref", searchParams.get("ref"));
  }

  React.useEffect(() => {
    if (cookie.token) {
      navigate("/dashboard");
    }

    fetch("https://api.goldearn.gg/general_info", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralStats(data);
      });
  }, [cookie, navigate]);

  return (
    <div className="w-full pb-4 px-[10%] md:px-40 xl:px-64">
      <Nav />

      <div
        onMouseOver={() => {
          setOfferHovered(true);
        }}
        onMouseLeave={() => {
          setOfferHovered(false);
        }}
        className="relative w-full lg:w-[40rem] left-0 right-0 m-auto mt-10 h-20 rounded-xl flex items-center justify-center overflow-hidden cursor-pointer"
      >
        <img
          src="/images/purple_city.avif"
          alt="Gold Pallete"
          className="w-full h-auto blur-lg"
        />
        <div
          className={classNames(
            "absolute font-bold text-xl sm:text-2xl px-4 text-center",
            {
              "text-brand-gold": offerHovered,
            }
          )}
        >
          Signup now and get 100 gold for free!
        </div>
      </div>

      <div className="flex justify-between flex-col 2xl:flex-row gap-2 mt-20">
        <div>
          <div className="gradient-background text-background w-fit font-bold text-3xl">
            Earn cash by doing what you love
          </div>

          <WhyWePay />
        </div>

        <div>
          <div className="gradient-background text-background w-fit font-bold text-3xl mt-10 2xl:mt-0">
            We pay you with...
          </div>

          <CompactPayouts />
          <LargePayouts />
        </div>
      </div>

      <Card className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-20 w-full h-fit lg:h-52 mt-20">
        <StatBlock
          image="users.png"
          title="Registered Users"
          description={generalStats?.users || "Loading..."}
        />

        <StatBlock
          image="ticket.png"
          title="Gold Earned"
          description={generalStats?.earned || "Loading..."}
        />

        <StatBlock
          image="laptop.png"
          title="Offers Completed"
          description={generalStats?.offers_completed || "Loading..."}
        />

        <StatBlock
          image="receipt.png"
          title="Paid Out"
          description={generalStats?.paid || "Loading..."}
        />
      </Card>

      <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row w-full mt-20">
        <div className="w-fit lg:w-1/2 grow flex items-center justify-center text-4xl font-bold">
          FAQ
        </div>
        <div className="flex flex-col items-center justify-center w-full">
          <Card className="flex flex-col gap-2 w-full lg:w-[80%] h-fit">
            <FAQItem
              question="What is goldearn?"
              answer="goldearn is a free to use platform that allows anyone to earn money by completing simple tasks such as downloading apps, watching videos, and completing surveys. We pay you with digital game goods such as in-game currency, skins, and gift cards or cash and crypto currency."
            />
            <FAQItem
              question="Is goldearn safe to use?"
              answer="Definitely! We never require any private information such as your game account passwords. For some games, you will only need to provide the username of the game account you want the digital currency to be sent to. For other games, we will simply give you a gift card with a balance for you to redeem in-game."
            />
            <FAQItem
              question="How does it work?"
              answer="We use the money from the offer companies to purchase digital game goods to reward to our users, keeping a small commission for ourselves! Users can instantly redeem their 'gold' for the digital game goods of their choosing. This creates a fair, win-win solution for everyone involved!"
            />
          </Card>
        </div>
      </div>

      <div className="opacity-50 mt-40 text-center">
        © 2023 goldearn - We are not affiliated with any of the companies or
        games displayed on this website
      </div>
    </div>
  );
}

export default App;
