import React from "react";
import classNames from "classnames";

export const Button = ({ label, color, className, onClick }) => {
  return (
    <button
      className={classNames(
        className,
        "text-white font-bold py-2 px-4 rounded-md shadow-md duration-100 hover:bg-opacity-80",
        {
          "hover:opacity-80 shadow-none": !color,
          "bg-brand-purple": color === "purple",
          "bg-brand-pink": color === "pink",
          "bg-red-500": color === "red",
          "gradient-background hover:brightness-110": color === "gradient",
        }
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
