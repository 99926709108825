export const LargePayouts = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full mt-8 gap-2 overflow-hidden visible 2xl:hidden">
      <img
        src="/images/paypal.webp"
        alt="paypal"
        className="w-full aspect-video rounded-md"
      />
      <img
        src="/images/roblox.png"
        alt="roblox"
        className="w-full aspect-video rounded-md"
      />
      <img
        src="/images/minecraft.jpg"
        alt="minecraft"
        className="w-full aspect-video rounded-md"
      />
      <img
        src="/images/nitro 2.avif"
        alt="discord nitro"
        className="w-full aspect-video rounded-md"
      />
      <div className="w-full aspect-video rounded-md overflow-hidden bg-white flex items-center justify-center">
        <img
          src="/images/litecoin.png"
          alt="litecoin"
          className="max-h-[90%] max-w-[90%]"
        />
      </div>
      <img
        src="/images/csgo.jpg"
        alt="csgo thumbnail"
        className="w-full aspect-video rounded-md"
      />
    </div>
  );
};
