export const CompactPayouts = () => {
  return (
    <div className="hidden 2xl:flex flex-col gap-2 mt-8 w-[32rem] h-fit">
      <div className="flex gap-2">
        <div className="w-full h-28 rounded-md overflow-hidden flex items-center justify-center">
          <img src="/images/paypal.webp" alt="paypal" />
        </div>

        <div className="w-80 h-28 rounded-md overflow-hidden flex items-center justify-center">
          <img src="/images/roblox.png" alt="roblox" />
        </div>
      </div>

      <div className="flex gap-2">
        <div className="w-80 h-28 rounded-md overflow-hidden flex items-center justify-center">
          <img src="/images/minecraft.jpg" alt="minecraft" />
        </div>

        <div className="w-full h-28 rounded-md overflow-hidden flex items-center justify-center">
          <img src="/images/nitro 2.avif" alt="discord nitro" />
        </div>
      </div>

      <div className="flex gap-2">
        <div className="w-full h-28 rounded-md overflow-hidden bg-white flex items-center justify-center">
          <img
            src="/images/litecoin.png"
            alt="litecoin"
            className="max-h-[90%] max-w-[90%]"
          />
        </div>
        <div className="w-80 h-28 rounded-md overflow-hidden flex items-center justify-center">
          <img src="/images/csgo.jpg" alt="csgo thumbnail" />
        </div>
      </div>
    </div>
  );
};
