export const OfferwallThumbnail = ({ image, link, comingSoon }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="relative cursor-pointer"
    >
      <img
        src={image}
        alt={image}
        className="w-full aspect-video hover:opacity-80 duration-100"
      />
      {comingSoon && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-brand-gold text-brand-dark font-bold text-xl px-4 py-2 rounded-md max-w-[90%] sm:max-w-none text-center">
            Coming Soon
          </div>
        </div>
      )}
    </a>
  );
};
