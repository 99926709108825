import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const FAQItem = ({ question, answer }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);

  return (
    <div>
      <div className="w-full overflow-hidden transition-all cursor-pointer bg-brand-super-light rounded-md px-4">
        <div
          className="flex items-center justify-between text-lg rounded-md h-14 select-none"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          {question}
          {showDropdown && <FontAwesomeIcon icon={faChevronUp} />}
          {!showDropdown && <FontAwesomeIcon icon={faChevronDown} />}
        </div>
      </div>
      {showDropdown && (
        <div className="animate-accordion rounded-md mt-1 px-4">{answer}</div>
      )}
    </div>
  );
};
