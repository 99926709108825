import React from "react";
import { useCookies } from "react-cookie";
import useGlobalState from "../state/globalState";
import { Last7DaysChart } from "../components/dashboard/Last7DaysChart";
import { DashboardNav } from "../components/DashboardNav";
import { StatBox } from "../components/dashboard/StatBox";
import { SocialLink } from "../components/dashboard/SocialLink";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Dashboard = () => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  const [userData] = useGlobalState("userData");
  const [offersCompletedLast7Days, setOffersCompletedLast7Days] =
    React.useState(0);

  return (
    <div className="flex flex-col gap-8 px-8 pb-20 sm:px-20 lg:px-44">
      <DashboardNav activeTab="dashboard" />

      <div className="flex w-full justify-center">
        <div className="w-fit h-fit px-4 py-2 bg-brand-light bg-opacity-80 border-[1px] border-yellow-400 flex items-center gap-8 rounded-md">
          <FontAwesomeIcon
            icon={faWarning}
            className="h-16 w-16 text-yellow-400"
          />
          <div>
            <div className="font-bold text-yellow-400 w-full text-center text-xl">
              WARNING
            </div>
            If you do not see your balance update, please{" "}
            <b>clear your cache or hard refresh with CTRL + F5</b>.
            <div>
              If that doesn't work, join our discord{" "}
              <a
                href="https://discord.gg/bvh9CCF2xV"
                target="_blank"
                rel="noreferrer"
                className="text-green-500"
              >
                https://goldearn.gg/discord
              </a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="cursor-pointer hover:opacity-80 duration-200 w-fit"
          onClick={() => {
            fetch("https://api.goldearn.gg/force_update", {
              method: "GET",
              headers: {
                Authorization: `Bearer ${cookies.token}`,
              },
            }).then((res) => {
              if (res.status === 200) {
                window.location.reload();
              }
            });
          }}
        >
          Click here to force update your stats
        </div>
        <div className="flex flex-col lg:flex-row gap-4 w-full h-fit">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-2 lg:w-2/3 xl:w-1/2">
            <StatBox
              value={userData && userData.offers_completed.length}
              label="Offers Completed"
            />
            <StatBox
              value={
                userData &&
                Math.floor((userData.balance + userData.paid) * 100) / 100
              }
              label="Gold Earned In Total"
            />
            <StatBox
              value={userData && Math.floor(userData.paid * 100) / 100}
              label="Withdrawn"
            />
            <StatBox
              value={userData && Math.floor(userData.balance * 100) / 100}
              label="Available Gold"
            />
          </div>
          <div className="flex flex-col gap-2 bg-brand-light rounded-md w-full aspect-video lg:aspect-auto p-4 max-h-full">
            <div className="flex justify-between items-end">
              <div className="text-xl">Earnings in the last 7 days</div>
              <div className="text-sm opacity-50">
                {offersCompletedLast7Days} offers completed
              </div>
            </div>
            <div className="w-full h-full">
              <Last7DaysChart
                setOffersCompletedLast7Days={setOffersCompletedLast7Days}
              />
            </div>
          </div>
        </div>

        <div className="mt-20">
          <div className="text-3xl">Socials</div>
          <div className="opacity-70">
            Follow our social media for free gold!
          </div>

          <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
            <SocialLink
              image="/images/discord.png"
              link={
                "https://api.goldearn.gg/claim?t=discord&tok=" +
                cookies["token"]
              }
            />

            <SocialLink
              image="/images/reddit.png"
              link={
                "https://api.goldearn.gg/claim?t=reddit&tok=" + cookies["token"]
              }
            />

            {/* <SocialLink
            image="/images/twitter.jpg"
            link={
              "https://api.goldearn.gg/claim?t=twitter&tok=" + cookies["token"]
            }
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
