import React from "react";

export const Card = ({ children, className = "" }) => {
  return (
    <div
      className={`${className} gap-2 p-4 rounded-md shadow-md bg-brand-light`}
    >
      {children}
    </div>
  );
};
