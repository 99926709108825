import React from "react";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import Checkbox from "react-simple-checkbox";
import { useNavigate } from "react-router-dom";

import { validateEmail } from "../utils/validation";
import { useCookies } from "react-cookie";

export const Signup = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["cookie-name"]);

  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [agreedToTOS, setAgreedToTOS] = React.useState(false);

  const [error, setError] = React.useState(undefined);

  const signup = () => {
    if (!username) setError("Username is required.");
    else if (!email) setError("Email is required.");
    else if (!password) setError("Password is required.");
    else if (!agreedToTOS) setError("You must agree to the terms of service.");
    else setError(undefined);

    if (!validateEmail(email)) {
      setError("Invalid email.");
      return;
    }

    if (!username || !email || !password || !agreedToTOS) return;

    fetch("https://api.goldearn.gg/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        email: email,
        password: password,
        ref: localStorage.getItem("ref"),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          return;
        }

        setCookie("token", data.token, {
          path: "/",
        });

        navigate("/dashboard");
      });
  };

  return (
    <div className="absolute flex w-full h-full flex-col justify-center items-center">
      <div className="flex flex-col w-[24rem] justify-center items-center gap-4">
        <div className="text-6xl gradient-background text-background font-bold">
          SIGNUP
        </div>

        <div className="flex flex-col gap-2 w-full">
          {error && <div className="text-red-500 w-full">Error: {error}</div>}

          <Input
            placeholder="Username"
            className="w-full"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />

          <Input
            placeholder="Email"
            className="w-full"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <Input
            placeholder="Password"
            type="password"
            className="w-full"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>

        <div className="relative flex w-full h-fit items-center gap-2 ">
          <Checkbox
            tickAnimationDuration={50}
            size={2}
            color="#3F52FF"
            className="absolute bottom-0"
            checked={agreedToTOS}
            onChange={(val) => {
              setAgreedToTOS(val);
            }}
          ></Checkbox>
          <div className="mt-3">
            <span
              className="cursor-pointer"
              onClick={() => {
                setAgreedToTOS(!agreedToTOS);
              }}
            >
              I agree to the{" "}
            </span>
            <span
              className="text-brand-pink cursor-pointer"
              onClick={() => {
                navigate("/tos");
              }}
            >
              terms of service
            </span>
          </div>
        </div>

        <Button
          label="CONTINUE"
          color="purple"
          className="w-full"
          onClick={signup}
        />
      </div>
    </div>
  );
};
