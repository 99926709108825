export const VerifyAADS = () => {
  return (
    <iframe
      data-aa="2195184"
      src="//ad.a-ads.com/2195184?size=728x90"
      style={{
        width: "728px",
        height: "90px",
        border: "0px",
        padding: "0",
        overflow: "hidden",
        backgroundColor: "transparent",
      }}
      title="A-Ads"
    ></iframe>
  );
};
