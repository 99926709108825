import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useGlobalState from "../state/globalState";

export const useUpdateUserData = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useGlobalState("userData");
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  React.useEffect(() => {
    if (!cookies.token) {
      const pathName = window.location.pathname;
      if (
        pathName === "/" ||
        pathName.startsWith("/login") ||
        pathName.startsWith("/signup") ||
        pathName.startsWith("/verify_aads")
      )
        return;
      navigate("/");
    }

    fetch("https://api.goldearn.gg/info", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: cookies.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setUserData(undefined);
          removeCookie("token");
          navigate("/login");
          return;
        }
        setUserData(data);
      });
  }, [cookies.token, navigate, removeCookie, setUserData]);
};
