import React from "react";
import { DashboardNav } from "../components/DashboardNav";
import { Thumbnail } from "../components/payout/Thumbnail";

export const Payout = () => {
  return (
    <div className="flex flex-col gap-8 px-8 pb-20 sm:px-20 lg:px-44">
      <DashboardNav activeTab="payout" />

      <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-2">
        <Thumbnail image="/images/payouts/Roblox.png" link="/payout/roblox" />
        <Thumbnail image="/images/payouts/Discord.png" link="/payout/discord" />
        <Thumbnail
          image="/images/payouts/Litecoin.png"
          link="/payout/litecoin"
        />
        <Thumbnail image="/images/payouts/nordvpn.png" link="/payout/nordvpn" />
        <Thumbnail image="/images/payouts/PayPal.png" disabled />
        <Thumbnail image="/images/payouts/CSGO.png" disabled />
      </div>
    </div>
  );
};
