import { WhyCard } from "./WhyCard";

export const WhyWePay = () => {
  return (
    <div className="grid grid-flow-row-dense grid-cols-1 sm:grid-cols-3 gap-2 mt-8 w-full 2xl:max-w-[45rem] h-[calc(100%-4.25rem)]">
      <WhyCard
        image="games"
        title="PLAY GAMES"
        description="Play simple games, reach levels, get achievements and earn money."
      />

      <WhyCard
        image="video"
        title="WATCH VIDEOS"
        description="Watch Ads, Movies, TV Shows while earning money."
      />

      <WhyCard
        image="poll"
        title="FILL SURVEYS"
        description="Fill out surveys and quizzes for quicker money."
        className="2xl:col-span-1"
      />
    </div>
  );
};
