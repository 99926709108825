import React from "react";

import { DashboardNav } from "../../components/DashboardNav";
import { JoinDiscordBanner } from "../../components/payout/JoinDiscordBanner";
import { RedeemCard } from "../../components/payout/RedeemCard";
import "../../styles/payouts.css";

export const Roblox = () => {
  return (
    <div className="flex flex-col gap-8 px-8 pb-20 sm:px-20 lg:px-44">
      <DashboardNav activeTab="payout" />

      <JoinDiscordBanner start={"Roblox Gift Card"} />

      <div className="grid grid-cols-1 xl:px-40 2xl:px-0 2xl:grid-cols-2 2xl:gap-x-20 gap-y-4 sm:gap-y-10 mt-10">
        <RedeemCard
          title="Roblox Gift Card - $5"
          price="5,000"
          image="/images/payouts/roblox5.jpg"
        />
        <RedeemCard
          title="Roblox Gift Card - $10"
          price="10,000"
          image="/images/payouts/roblox5.jpg"
        />
        <RedeemCard
          title="Roblox Gift Card - $25"
          price="25,000"
          image="/images/payouts/roblox5.jpg"
        />
        <RedeemCard
          title="Roblox Gift Card - $50"
          price="50,000"
          image="/images/payouts/roblox5.jpg"
        />
      </div>
    </div>
  );
};
