export const StatBox = ({ value, label }) => {
  return (
    <div className="relative w-full aspect-square bg-brand-light rounded-md">
      <img
        src="/images/dollar_gray.png"
        alt="Gray Coin"
        className="absolute top-0 left-0 right-0 bottom-0 m-auto w-[60%]"
      />
      <div className="w-full mt-[calc(50%-1.25rem)] flex flex-col items-center justify-center text-center">
        <div className="text-3xl font-bold">{value}</div>
        <div className="text-sm sm:text-base opacity-60">{label}</div>
      </div>
    </div>
  );
};
