import React from "react";
import { Card } from "../components/Card";
import { DashboardNav } from "../components/DashboardNav";
import useGlobalState from "../state/globalState";

export const Referrals = () => {
  const [userData] = useGlobalState("userData");

  // TODO: FIX THE FUCKING REFERRAL MONEY AND COUNT (DOESN'T UPDATE)

  return (
    <div className="w-full">
      <DashboardNav activeTab="referrals" />

      <Card className="flex flex-col items-center w-fit max-w-[90%] m-auto">
        <div className="text-center opacity-80">
          Share your link below and earn 10% of whatever someone earns forever!
        </div>
        <div className="text-center opacity-80">
          The user you refer will also get 100 Gold to get them started
        </div>

        <div className="bg-brand-super-light px-6 py-2 text-green-500 w-fit rounded-md text-base hover:text-green-400">
          {userData?.ref_id
            ? `https://goldearn.gg/?ref=${userData.ref_id}`
            : "Loading..."}
        </div>
        <div className="bg-brand-super-light px-6 py-2 text-green-500 w-fit rounded-md text-base hover:text-green-400">
          {userData
            ? `You have earned ${
                Math.floor(userData.ref_earnings * 100) / 100
              } Gold from ${userData.refs.length} referral${
                userData.refs.length === 1 ? "" : "s"
              } so far`
            : "Loading..."}
        </div>
      </Card>
    </div>
  );
};
