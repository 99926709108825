import React from "react";

export const Input = ({
  label,
  placeholder,
  type = "text",
  value,
  className = "",
  onChange,
}) => {
  return (
    <div className="w-full">
      <label className="text-xs font-bold opacity-50">{label}</label>
      <input
        className={
          className +
          " border-b-[2px] border-brand-pink bg-brand-light outline-none w-full px-4 py-2 rounded-md"
        }
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
